/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.without_ampm::-webkit-datetime-edit-ampm-field {
    display: none;
}
input[type=time]::-webkit-clear-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -ms-appearance:none;
    appearance: none;
    margin: -10px; 
}

.mat-datepicker-toggle {
    position: absolute !important;
    right: 0 !important;
}

.mat-datepicker-toggle .mat-icon-button {
    margin-top: 12px !important;
}
.ngx-mat-timepicker .mat-icon-button {
    display: none !important;
}

.ngx-mat-timepicker-meridian .mat-button {
    margin-left: 5px !important;
    border-radius: 5px !important;
    background-color: black !important;
    color: white !important;
}

.ngx-mat-timepicker-meridian .mat-input-element {
    color: rgb(113, 113, 122) !important;
    border: none !important;
}

.success-snackbar {
    background: rgb(20, 184, 166);
    color: white;
}

.failure-snackbar {
    background: rgb(184, 45, 20);
    color: white;
}
.warning-snackbar {
    background: rgb(243, 149, 42);
    color: white;
}

.c-btn {
    height: 30px !important;
}

.selected-list .c-list {
    display: flex;
    max-width:93%;
    overflow-x: hidden;
    overflow-y: hidden;
    /* padding-bottom: 2px !important; */
}

.selected-list .c-list .c-token {
    margin-right: 5px !important;
    background: rgba(244, 245, 250, 0.8)
}
.selected-list .c-list .c-token .c-label {
    white-space: nowrap;
    color: rgb(19, 20, 21);
    font-size: 0.8vw;
}

.selected-list .c-list ::-webkit-scrollbar-thumb {
    width: 3px !important;
    background: #979797 !important;
    border-radius: 40px;
}

.selected-list .c-list ::-webkit-scrollbar-track {
    width: 3px !important;
    background: transparent !important;
}

.selected-list .c-list .c-token .c-remove svg {
    fill: rgb(19, 20, 21) !important;
}

.clear-all {
    display: none;
}
